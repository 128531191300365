<template>
    <div>
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="问题:">
                    <el-input v-model="filters.question" @input="(e) => (filters.question = utils.validForbid(e))"
                    placeholder="请输入问题" clearable @clear='clearContent'></el-input>
                </el-form-item>
                <el-form-item label="来源:">
                    <el-select v-model="filters.dataType" clearable>
                        <el-option label="对话数据" value="1"></el-option>
                        <el-option label="导入数据" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="意图:">
                    <el-select v-model="filters.contentType" clearable filterable remote placeholder="选择意图分类"
                        :loading="knowledgeLoading">
                        <el-option v-for="item in contentTypeData" :key="item.key" :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否标记完成:">
                    <el-select v-model="filters.isMarkComplete" clearable>
                        <el-option label="是" :value="true"></el-option>
                        <el-option label="否" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否分配:">
                    <el-select v-model="filters.assignMarkStatus" clearable>
                        <el-option label="是" :value="true"></el-option>
                        <el-option label="否" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="完成时间:">
                    <el-date-picker v-model="filters.markComplete" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <el-table :data="informationList" highlight-current-row @current-change="selectCurrentRow"
            v-loading="listLoading" @selection-change="selsChange" style="width: 100%" border>
            <!-- <el-table-column prop="dataNo" label="数据编号" width="130" align="center"></el-table-column> -->
            <!-- <el-table-column prop="trainName" label="数据分类" width="130" align="center"></el-table-column> -->
            <el-table-column label="问题" width align="center">
                <template slot-scope="scope">
                    <!-- <div :id="`question-${scope.row.dataId}`">{{ scope.row.dataQuestion }}</div> -->
                    <div :style="scope.row.slotList.length > 0 && 'line-height: 100px !important; '"
                        :id="`question-${scope.row.dataId}`" v-html="scope.row.dataQuestion"></div>
                </template>
            </el-table-column>
            <el-table-column prop="contentTypeName" label="意图分类" width="100" align="center"></el-table-column>
            <el-table-column label="是否标记完成" width="120" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.isMarkComplete == true ? "是" : "否" }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="assignMarkUserName" label="分配人" width="100" align="center"></el-table-column>
            <el-table-column prop="assignMarkStatus" label="是否已分配" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.assignMarkStatus == true ? "是" : "否" }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="markUserName" label="标记人" width="100" align="center"></el-table-column>
            <el-table-column prop="markCompleteTime" label="完成时间" width="180" align="center"></el-table-column>
            <el-table-column align="center" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click.native="toTrain(scope.row)">标记</el-button>
                    <el-button type="text" size="small" @click.native="deleteFunction(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-col :span="24" class="pageBar" align="center">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <el-dialog title="生成训练集" :visible.sync="trainingSetDialogFormVisible" v-model="trainingSetDialogFormVisible"
            :close-on-click-modal="false">
            <el-form :model="trainingSetForm" ref="trainingSetForm" :rules="trainingSetFormRules"
                style="width:80%;margin-left: 20px; padding-bottom: 0px;">
                <el-form-item label="训练集名称:" prop="trainName" label-width="100px">
                    <el-input v-model="trainingSetForm.trainName"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="closeTrainingSet">取 消</el-button>
                <el-button type="primary" @click.native="saveTrainingSet()" :loading="trainingSetLoading">确
                    定</el-button>
            </div>
        </el-dialog>

        <!--导入excel数据-->
        <el-dialog title="导入数据" :visible.sync="importExcelDataFormVisible" v-model="importExcelDataFormVisible"
            :close-on-click-modal="false">
            <el-form v-if="importExcelDataFormVisible" label-width="130px">
                <el-form-item label="导入数据:">
                    <el-upload class="upload-demo" drag :action="`/omswebapi/data/importExcelData`" :headers="headers"
                        :limit="1" :on-success="upLoadSuccess" :on-progress="upLoadProgress"
                        v-loading.fullscreen.lock="fullscreenLoading">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__text">目前只支持只支持Excel文件，每个文件不超过100MB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="dataId ? '标记' : '快速标记'" :visible.sync="quickTagVisible" @close="handleCloseMarkTag">
            <MarkTag v-if="quickTagVisible" :closeMarkTag="handleCloseMarkTag" :dataId="dataId"></MarkTag>
        </el-dialog>
        <el-dialog title="分配标记" :visible.sync="shareMarkVisible" width="30%">
            <el-form :model="shareMarkForm">
                <el-form-item label="未分配数量:">
                    {{ remainingCount }}条
                </el-form-item>
            </el-form>
            <el-form>
                <el-form-item label="分配人员:">
                    <el-select v-model="shareMarkForm.userIds" filterable multiple style="margin-left: 20px;"
                        placeholder="请选择">
                        <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="shareMarkVisible = false">取 消</el-button>
                <el-button type="primary" @click.native="saveShareMark()" :loading="shareMarkLoading">确
                    定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import util from "../../../util/date";
import {
    getInformationList,
    deleteInformation,
    getKnowledgeNames,
    generateTrainSet,
    getUserListPage,
    assignMark,
    getByNamespace
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import MarkTag from "../../components/MarkTag";
import Mark from 'mark.js'

export default {
    components: { Toolbar, MarkTag },
    data() {
        return {
            host: process.env.VUE_APP_BASE_URL,
            utils: '',
            trainStatusList: [],
            informationList: [],
            listLoading: false,
            currentRow: null,
            //列表选中列
            sels: [],
            //增删改查按钮
            buttonList: [],
            filters: {
                dataType: null,
                contentType: null,
                isMarkComplete: null,
                markComplete: '',
                assignMarkStatus: null,
                question: ''
            },
            //关于分页的obj
            pages: {
                pageSize: 20, //默认每页条数
                pageArr: [10, 20, 30, 40], //默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            deleteLoading: false,
            trainingSetDialogFormVisible: false,
            trainingSetLoading: false,
            trainingSetFormRules: {
                trainName: [
                    { required: true, message: '请输入训练集名称', trigger: 'blur' }
                ]
            },
            trainingSetForm: {
                trainName: '',
            },
            knowledgeLoading: false,
            knowledgeList: [],
            //导入EXCEL数据界面是否显示
            importExcelDataFormVisible: false,
            importExcelDataLoading: false,
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("Token")}`,
            },
            quickTagVisible: false,
            dataId: null,
            shareMarkVisible: false,
            shareMarkForm: {
                userIds: []
            },
            shareMarkLoading: false,
            remainingCount: 0,
            userList: [],
            contentTypeData: [],
            fullscreenLoading: false

        }
    },
    methods: {
        getListData() {
            this.pages.pageIndex = 1
            this._getListData();

        },
        // 清空筛选框内容，重新获取数据
        clearContent() {
            this._getListData();
        },
        //当前行发生变化时
        selectCurrentRow(val) {
            this.currentRow = val;
        },
        //选项发生变化时
        selsChange: function (sels) {
            this.sels = sels;
        },
        //获取训练状态
        getKnowledgeList(query) {
            var _this = this;
            if (query !== '') {
                _this.knowledgeLoading = true;
                var params = {
                    knowledgeName: query,
                };
                setTimeout(() => {

                    getKnowledgeNames(params).then((res) => {
                        this.knowledgeLoading = false;
                        var data = res.data;
                        // var resData = [data.response];
                        if (data.success) {
                            _this.knowledgeList = data.response;
                        }
                    });

                }, 200);
            } else {
                _this.knowledgeList = [];
            }
        },
        _getListData() {
            this.listLoading = true;
            var params = {
                dataType: this.filters.dataType,
                contentType: this.filters.contentType,
                isMarkComplete: this.filters.isMarkComplete,
                markCompleteEndTime: this.filters.markComplete[0],
                markCompleteStartTime: this.filters.markComplete[1],
                assignMarkStatus: this.filters.assignMarkStatus,
                question: this.filters.question,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            getInformationList(params).then((res) => {
                this.listLoading = false;
                this.informationList = res.data.response.data;
                this.pages.dataCount = res.data.response.dataCount;
                this.pages.pageIndex = res.data.response.pageIndex;
                if (res.data.response.pageSize > 0) {
                    this.pages.pageSize = res.data.response.pageSize;
                }
                if (res.data.status) {
                    this.$nextTick(() => {
                        res.data.response.data.map((i) => {
                            const selectedTextList = i.slotList?.map((t) => ({
                                tag_id: t.slotId,
                                tag_name: t.slotName,
                                tag_color: t.slotColor,
                                start: t.slotTextStartIndex,
                                end: t.slotTextEndIndex,
                                mark_content: t.slotDataText,
                                mark_id: new Date().getTime(),
                            }))
                            const markList = selectedTextList?.map((j) => {
                                return {
                                    ...j,
                                    start: j.start, //必备
                                    length: j.mark_content.length, //必备
                                }
                            }) || []
                            const marker = new Mark(document.getElementById(`question-${i.dataId}`))
                            marker.unmark()
                            this.$nextTick(() => {
                                markList?.forEach?.(function (m) {
                                    marker.markRanges([m], {
                                        element: 'span',
                                        className: 'text-selected',
                                        each: (element) => {
                                            element.setAttribute('id', m.mark_id)
                                            element.style.borderBottom = `2px solid ${m.tag_color}`
                                            element.style.color = m.tag_color
                                            element.style.userSelect = 'none'
                                            element.style.paddingBottom = '1px'
                                            element.dataset.content = m.tag_name
                                            element.style.position = 'relative'
                                        },
                                    })
                                })

                            })
                        })
                    })
                }
            });
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        //分页
        handleCurrentChange(val) {
            // if(this.filters.name != '' || this.filters.selectc != false){
            //   this.pages.pageIndex = 1
            // }else{
            this.pages.pageIndex = val;
            // }      
            this._getListData();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this._getListData();
        },
        toTrain(row) {
            this.dataId = row.dataId
            this.quickTagVisible = true
        },
        releaseFunction() {

        },
        shareMark() {
            this.shareMarkVisible = true
            getInformationList({ isMarkComplete: false, pageIndex: 1, pageSize: 1, assignMarkStatus: false }).then((res) => {
                this.remainingCount = res.data.response.dataCount;
            });
            getUserListPage({ pageIndex: 1, pageSize: 999 }).then((res) => {
                this.userList = res.data.response.data
            })
            this.shareMarkForm = {
                userIds: []
            }
        },
        quickTag() {
            this.dataId = null
            this.quickTagVisible = true
        },
        deleteFunction(val) {
            this.$confirm('确认删除吗？', '提示', {}).then(() => {
                this.deleteLoading = true;
                var params = { dataId: val.dataId };
                deleteInformation(params).then((res) => {
                    if (util.isEmt.format(res)) {
                        this.deleteLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.deleteLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        this._getListData();
                    }
                    else {
                        this.deleteLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        },
        //生成训练集显示
        generateTrainingSet: function () {
            var _this = this;
            _this.trainingSetForm.trainName = '';
            _this.trainingSetDialogFormVisible = true;
        },
        closeTrainingSet: function () {
            var _this = this;
            this.trainingSetDialogFormVisible = false;
            _this.trainingSetForm.trainName = '';
        },
        saveTrainingSet: function () {
            this.$refs.trainingSetForm.validate((valid) => {
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.trainingSetLoading = true;
                        let params = Object.assign({}, this.trainingSetForm);
                        generateTrainSet(params).then((res) => {
                            if (util.isEmt.format(res)) {
                                this.trainingSetLoading = false;
                                return;
                            }
                            if (res.data.success) {
                                this.trainingSetLoading = false;
                                this.$message({
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.trainingSetDialogFormVisible = false;
                                // this.getDataPermissions();
                            }
                            else {
                                this.trainingSetLoading = false;
                                this.$message({
                                    message: res.data.message,
                                    type: 'error'
                                });
                            }
                        });
                    });
                }
            });
        },
        importExcelData: function () { //导入excel数据
            this.importExcelDataFormVisible = true;
        },
        upLoadSuccess(res) {
            if (res.success) {
                this._getListData();
                this.importExcelDataFormVisible = false;
                this.fullscreenLoading = false
                localStorage.removeItem('quickMarkPage');
            } else {
                this.$message({
                    message: '上传失败, 请重试',
                    type: "error",
                });
            }
        },
        upLoadProgress(res) {
            this.fullscreenLoading = true
        },
        handleCloseMarkTag() {
            this._getListData();
            this.quickTagVisible = false
            this.dataId = null
        },
        saveShareMark() {
            this.shareMarkLoading = true
            assignMark(this.shareMarkForm).then((res) => {
                if (res.data.success) {
                    this.$message({
                        message: res.data.message,
                        type: 'success'
                    });
                    this.shareMarkVisible = false
                    this.shareMarkLoading = false
                } else {
                    this.$message({
                        message: res.data.message,
                        type: 'error'
                    });
                    this.shareMarkLoading = false
                }
            })
        },
        getContentTypeList() {
            getByNamespace({ namespace: 'BizService.OmsAicsService.ContentType' }).then(res => {
                this.contentTypeData = res.data.response
            })
        },

    },
    mounted() {
		this.utils = util
        this.getContentTypeList();
        this._getListData();
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
};
</script>
<style scoped>
::v-deep .text-selected::after {
    content: attr(data-content);
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    line-height: 1.2;
    top: 25px;
    /* white-space: nowrap; */
    /* text-overflow: center; */
}
</style>